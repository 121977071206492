import React from "react";

export default function StatsInfo() {
  return (
    <>
      <div className="stats-info py-5">
        <div className="container py-xl-5 py-lg-3">
          <h4 className="stat-text-wthree text-wh mx-auto text-center font-italic mb-sm-5 mb-4">
            We give you Care tips and Ideas on what style suits your garden
          </h4>
          <div className="row py-5">
            <div className="col-lg-3 col-sm-6 stats-grid-w3-agile">
              <div className="row">
                <div className="col-4 icon-right-w3ls text-sm-left text-center">
                  <span className="fa fa-smile-o" data-blast="bgColor"></span>
                </div>
                <div className="col-8">
                  <p className="counter font-weight-bold text-wh">1280</p>
                  <p className="text-li mt-2" data-blast="color">
                    Happy Customers
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-sm-0 mt-4">
              <div className="row">
                <div className="col-4 icon-right-w3ls text-sm-left text-center">
                  <span className="fa fa-shield" data-blast="bgColor"></span>
                </div>
                <div className="col-8">
                  <p className="counter font-weight-bold text-wh">920</p>
                  <p className="text-li mt-2" data-blast="color">
                    Awards Win
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-lg-0 mt-4">
              <div className="row">
                <div className="col-4 icon-right-w3ls text-sm-left text-center">
                  <span className="fa fa-leaf" data-blast="bgColor"></span>
                </div>
                <div className="col-8">
                  <p className="counter font-weight-bold text-wh">1400</p>
                  <p className="text-li mt-2" data-blast="color">
                    Products
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 stats-grid-w3-agile mt-lg-0 mt-4">
              <div className="row">
                <div className="col-4 icon-right-w3ls text-sm-left text-center">
                  <span
                    className="fa fa-thumbs-o-up"
                    data-blast="bgColor"
                  ></span>
                </div>
                <div className="col-8">
                  <p className="counter font-weight-bold text-wh">1080</p>
                  <p className="text-li mt-2" data-blast="color">
                    Ratings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
