import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="text-center py-4">
        <div className="container py-xl-5 py-4">
          <h3 className="text-da let mb-3" id="we_services">
            We Provide Best Services
          </h3>
          <p className="footer-para mx-auto">
            Natus error sit voluptatem accusantium doloremque laudantium, totam
            rem aperiam doloremque laudantium, totam rem aperiam
          </p>
          <h2 className="logo2 text-center mt-3">
            <Link
              className="bdt-slide-btn"
              to={() => {
                return false;
              }}
            >
              ECOBGC<i className="fa fa-pagelines" aria-hidden="true"></i>
            </Link>
          </h2>
          <div className="contact-left-footer mt-4">
            <h6 className="text-da let mb-3">
              Sed do eiusmod tempor incididunt ut labore et dolore
            </h6>
            <ul>
              <li>
                <p>
                  <i className="fa fa-map-marker mr-2"></i>2160 Kingston court,
                  Marietta GA 30067
                </p>
              </li>
              <li className="mx-4">
                <p>
                  <i className="fa fa-phone mr-2"></i>+1 678-404-2662.
                </p>
              </li>
              <li>
                <p className="text-wh">
                  <i className="fa fa-envelope-open mr-2"></i>
                  <a href="mailto:ourcgm@gmail.com">ourcgm@gmail.com</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="footercopy-social mt-4">
            <ul>
              <li>
                <a href="http://www.facebook.com/ecobgc" target={"_blank"}>
                  <span className="fa fa-facebook-f"></span>
                </a>
              </li>
              <li className="mx-2">
                <a href="#">
                  <span className="fa fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-rss"></span>
                </a>
              </li>
              <li className="ml-2">
                <a href="#">
                  <span className="fa fa-vk"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="w3l-copy text-center mt-5">
            <p className="text-da">© 2022 ECOBGC. All rights reserved</p>
          </div>
        </div>
      </footer>
      <a href="#home" className="move-top text-center"></a>
    </>
  );
}
