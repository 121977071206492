import React from "react";

export default function AboutUs() {
  return (
    <>
      <section className="about" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 about-left-w3pvt offset-lg-1">
              <div className="main-img">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ab.jpg`}
                  alt=""
                  className="img-fluid pos-aboimg"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ab2.jpg`}
                  alt=""
                  className="img-fluid pos-aboimg2"
                />
              </div>
            </div>
            <div className="col-lg-6 about-right offset-lg-1">
              <h4 className="sub-tittle-w3layouts let fontsize_title">
                What We’re All About
              </h4>
              <p className="mt-4 mb-5">
                We at Barbie’s believe that{" "}
                <b>
                  “We Do Not Inherit the Earth from Our Ancestors; We Borrow It
                  from Our Children.”
                </b>{" "}
                Let's strive to pass on a greener place by practicing an
                eco-friendly lifestyle.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
