import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="header-style fixed-top">
        <div className="container">
          <div className="header d-lg-flex justify-content-between align-items-center">
            <h1 className="logo">
              <Link
                className="bdt-slide-btn"
                to={() => {
                  return false;
                }}
              >
                ECOBGC<i className="fa fa-pagelines" aria-hidden="true"></i>
              </Link>
            </h1>
            <div className="nav_w3ls">
              <nav className="pull-right">
                <label htmlFor="drop" className="toggle toogle-2">
                  Menu
                </label>
                <input type="checkbox" id="drop" />
                <ul className="menu">
                  <li className="active ml-0">
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <label htmlFor="drop-1" className="toggle toggle-drop">
                      Shop{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </label>
                    <a href="#we_services">
                      Shop{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </a>
                    <input type="checkbox" id="drop-1" />
                    <ul className="list-unstyled">
                      <li>
                        <a href="#services" className="drop-text">
                          Services
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Vegetables
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Flowers
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Fruits
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Herbs
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Greens
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <label htmlFor="drop-2" className="toggle toggle-drop">
                      Community Reach{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </label>
                    <a href="#CommunityReach">
                      Community Reach{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </a>
                    <input type="checkbox" id="drop-2" />
                    <ul className="list-unstyled">
                      <li>
                        <a href="#CommunityReach" className="drop-text">
                          Helping Fire Victims
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#Oldagehomes_first" className="drop-text">
                          Old age homes
                        </a>
                      </li>

                      <li className="my-2">
                        <a href="#Oldagehomes" className="drop-text">
                          NAAM
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#NAAM" className="drop-text">
                          Yugal Kunj
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#YugalKunj" className="drop-text">
                          Women
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#Women" className="drop-text">
                          Helping Local Farmers
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                  <li>
                    <a href="#Reviews">Reviews</a>
                  </li>
                  <li>
                    <label htmlFor="drop-3" className="toggle toggle-drop">
                      More{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </label>
                    <a href="#">
                      More{" "}
                      <span
                        className="fa fa-angle-down"
                        aria-hidden="true"
                      ></span>
                    </a>
                    <input type="checkbox" id="drop-3" />
                    <ul className="list-unstyled">
                      <li>
                        <a href="#" className="drop-text">
                          Growing Tips
                        </a>
                      </li>
                      <li className="my-2">
                        <a href="#" className="drop-text">
                          Seed Saving
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://fb.me/e/56bsfZ7pF"
                          target={"_blank"}
                          className="drop-text"
                        >
                          Upcoming Webinars
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
