import React from "react";

export default function Services() {
  return (
    <>
      <div className="serives-agile py-5" id="services">
        <div className="container py-xl-5 py-lg-3">
          <div className="row support-bottom text-center">
            <div className="col-md-4 services-w3ls-grid">
              <i className="fa fa-envira" aria-hidden="true"></i>
              <h4 className="text-wh text-uppercase mt-md-5 mt-4 mb-3">
                Garden Care
              </h4>
              <p>
                Ut enim ad minima veniam, quis nostrum ullam corporis suscipit
                laboriosam.
              </p>
            </div>
            <div className="col-md-4 services-w3ls-grid my-md-0 my-4">
              <i className="fa fa-tree" aria-hidden="true"></i>
              <h4 className="text-wh text-uppercase mt-md-5 mt-4 mb-3">
                Plant Renovation
              </h4>
              <p>
                Ut enim ad minima veniam, quis nostrum ullam corporis suscipit
                laboriosam.
              </p>
            </div>
            <div className="col-md-4 services-w3ls-grid">
              <i className="fa fa-pagelines" aria-hidden="true"></i>
              <h4 className="text-wh text-uppercase mt-md-5 mt-4 mb-3">
                Always Fresh
              </h4>
              <p>
                Ut enim ad minima veniam, quis nostrum ullam corporis suscipit
                laboriosam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
