import React, { useState, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";
import validator from "validator";

export default function ContactUs() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [ErrorMessageVisible, setErrorMessageVisible] = useState(false);
  const [ErrorMessageType, setErrorMessageType] = useState("error");
  const [ErrorMessage, setErrorMessage] = useState("");

  const showSnackbarMessage = useCallback(
    (IsVisible, MsgType, Msg) => {
      setErrorMessageVisible(IsVisible);
      setErrorMessageType(MsgType);
      setErrorMessage(Msg);
    },
    [setErrorMessageVisible, setErrorMessageType, setErrorMessage]
  );

  const SaveContact = useCallback(() => {
    if (Name === "") {
      showSnackbarMessage(true, "error", "Please enter name.");
      return false;
    }
    if (Email === "") {
      showSnackbarMessage(true, "error", "Please enter email address.");
      return false;
    }
    if (validator.isEmail(Email) === false) {
      showSnackbarMessage(true, "error", "Please enter valid email address.");
      return false;
    }
    if (Phone === "") {
      showSnackbarMessage(true, "error", "Please enter phone.");
      return false;
    }
    if (Subject === "") {
      showSnackbarMessage(true, "error", "Please enter subject.");
      return false;
    }
    if (Message === "") {
      showSnackbarMessage(true, "error", "Please enter message.");
      return false;
    }

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        ApiKey: process.env.REACT_APP_API_KEY,
        Accept: "application/json",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      }),
      body: JSON.stringify({
        Name: Name,
        Email: Email,
        Phone: Phone,
        Subject: Subject,
        Message: Message,
      }),
    };

    fetch(process.env.REACT_APP_API_PATH + "SaveContact", requestOptions)
      .then((result) => result.json())
      .then((response) => {
        if (response.Success === true) {
          showSnackbarMessage(
            true,
            "success",
            "Your contact request has been sent."
          );
          setName("");
          setEmail("");
          setPhone("");
          setSubject("");
          setMessage("");
        } else {
          showSnackbarMessage(true, "error", "Unable to sent contact request");
        }
      });
  }, [Name, Email, Message, showSnackbarMessage]);

  return (
    <>
      <Snackbar
        open={ErrorMessageVisible}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={4000}
        onClose={() => {
          setErrorMessageVisible(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessageVisible(false);
          }}
          severity={ErrorMessageType}
        >
          {ErrorMessage}
        </Alert>
      </Snackbar>
      <section className="contact py-5" id="contact">
        <div className="container py-xl-5 py-lg-3">
          <h3 className="w3ls-title text-wh text-center font-weight-bold mb-5">
            Contact <span className="font-weight-light">Us</span>
          </h3>
          <div className="row contact-form">
            <div className="offset-lg-2"></div>
            <div className="col-lg-8 wthree-form-left">
              <fieldset className="contact-top1" data-blast="borderColor">
                <legend className="text-wh let text-capitalize">
                  send us a note
                </legend>
                <form action="#" method="post" className="f-color">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="contactusername">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={Name}
                          onChange={(e) => setName(e.currentTarget.value)}
                          required
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="contactemail">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={Email}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                          required
                          placeholder="Enter your email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="contactusername">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Phone"
                          value={Phone}
                          onChange={(e) => setPhone(e.currentTarget.value)}
                          required
                          placeholder="Enter your phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="contactSubject">Subject</label>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={Subject}
                          onChange={(e) => setSubject(e.currentTarget.value)}
                          required
                          placeholder="Enter your subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contactcomment">Your Message</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="message"
                      value={Message}
                      onChange={(e) => setMessage(e.currentTarget.value)}
                      required
                      placeholder="Your message"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block"
                    data-blast="bgColor"
                    onClick={(e) => {
                      e.preventDefault();
                      SaveContact();
                    }}
                  >
                    Submit
                  </button>
                </form>
              </fieldset>
            </div>
            <div className="offset-lg-2"></div>
          </div>
          <div className="row address bg-li">
            <div className="col-lg-4 address-grid">
              <div className="row address-info">
                <div className="col-md-3 col-4 address-left text-lg-center text-sm-right text-center">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="col-md-9 col-8 address-right">
                  <p>
                    <a href="mailto:ourcgm@gmail.com">ourcgm@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 address-grid my-lg-0 my-4">
              <div className="row address-info">
                <div className="col-md-3 col-4 address-left text-lg-center text-sm-right text-center">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="col-md-9 col-8 address-right">
                  <p>+1 678-404-2662</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 address-grid">
              <div className="row address-info">
                <div className="col-md-3 col-4 address-left text-lg-center text-sm-right text-center">
                  <i className="fa fa-map"></i>
                </div>
                <div className="col-md-9 col-8 address-right">
                  <p> 2160 Kingston court,</p>
                  <p> Marietta GA 30067</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
