import React from "react";
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AboutUs from "./AboutUs";
import Services from "./Services";
import StatsInfo from "./StatsInfo";
import CommunityReach from "./CommunityReach";
import Gallery from "./Gallery";
import ContactUs from "./ContactUs";
import Reviews from "./Reviews";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>ECOBGC</title>
      </Helmet>
      <div id="home">
        <div className="banner_w3lspvt">
          <div className="csslider infinity" id="slider1">
            <input
              type="radio"
              name="slides"
              defaultChecked={true}
              id="slides_1"
            />
            <input type="radio" name="slides" id="slides_2" />
            <input type="radio" name="slides" id="slides_3" />
            <input type="radio" name="slides" id="slides_4" />
            <ul>
              <li>
                <div className="banner-top">
                  <div className="container">
                    <div className="w3layouts-banner-info text-center">
                      <h3 className="text-wh">Welcome to Plants</h3>
                      <p className="text-li mx-auto mt-2">
                        Ut enim ad minim quis nostrud exerci sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <a
                        href="#about"
                        className="button-style scroll mt-sm-5 mt-4"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="banner-top1">
                  <div className="container">
                    <div className="w3layouts-banner-info text-center">
                      <h3 className="text-wh">Plants For Healthy</h3>
                      <p className="text-li mx-auto mt-2">
                        Ut enim ad minim quis nostrud exerci sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <a
                        href="#about"
                        className="button-style scroll mt-sm-5 mt-4"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="banner-top2">
                  <div className="container">
                    <div className="w3layouts-banner-info text-center">
                      <h3 className="text-wh">Welcome to Plants</h3>
                      <p className="text-li mx-auto mt-2">
                        Ut enim ad minim quis nostrud exerci sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <a
                        href="#about"
                        className="button-style scroll mt-sm-5 mt-4"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="banner-top3">
                  <div className="container">
                    <div className="w3layouts-banner-info text-center">
                      <h3 className="text-wh">Plants For Healthy</h3>
                      <p className="text-li mx-auto mt-2">
                        Ut enim ad minim quis nostrud exerci sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                      <a
                        href="#about"
                        className="button-style scroll mt-sm-5 mt-4"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="arrows">
              <label htmlFor="slides_1"></label>
              <label htmlFor="slides_2"></label>
              <label htmlFor="slides_3"></label>
              <label htmlFor="slides_4"></label>
            </div>
          </div>
          <div className="icon-w3">
            <a href="#about" className="scroll">
              <i className="fa fa-angle-double-down" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <AboutUs />
      <Services />
      <StatsInfo />
      <CommunityReach />
      <Gallery />
      <Reviews />
      <ContactUs />
    </>
  );
}
