import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>kicksNflicks | 404 - Page Not Found</title>
        <link
          rel="stylesheet"
          href={`${process.env.PUBLIC_URL}/assets/css/style_404.css`}
          type="text/css"
          media="all"
        />
      </Helmet>
      <div className="section-spacing">
        <div className="container">
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-404"></div>
              <h1>404</h1>
              <h2>Oops! Page Not Be Found</h2>
              <br />
              <p>
                Sorry but the page you are looking for does not exist, have been
                removed. name changed or is temporarily unavailable
              </p>
              <br />
              <Link to="/home">Back to homepage</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
