import React from "react";

export default function Gallery() {
  return (
    <>
      <section className="portfolio py-5" id="gallery">
        <div className="container py-xl-5 py-lg-3">
          <h3 className="w3ls-title text-center font-weight-bold mb-5">
            Our <span className="font-weight-light">ECOBGC</span>
          </h3>
          <div className="row pt-4">
            <div className="col-md-3 gal-grid-wthree">
              <div className="gallery-demo">
                <a href="#gal1">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g1.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree my-md-0 my-4">
              <div className="gallery-demo">
                <a href="#gal2">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g2.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree">
              <div className="gallery-demo">
                <a href="#gal3">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g3.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
              <div className="gallery-demo">
                <a href="#gal4">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g4.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-3 gal-grid-wthree">
              <div className="gallery-demo">
                <a href="#gal5">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g5.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree my-md-0 my-4">
              <div className="gallery-demo">
                <a href="#gal6">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g6.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree">
              <div className="gallery-demo">
                <a href="#gal7">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g7.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
              <div className="gallery-demo">
                <a href="#gal8">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/g8.jpg`}
                    alt=" "
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="gal1" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g1.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal2" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g2.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal3" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g3.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal4" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g4.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal5" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g5.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal6" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g6.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal7" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g7.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
      <div id="gal8" className="popup-effect animate">
        <div className="popup">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/g8.jpg`}
            alt="Popup Image"
            className="img-fluid"
          />
          <h4 className="p-mask">Plants</h4>
          <a className="close" href="#gallery">
            &times;
          </a>
        </div>
      </div>
    </>
  );
}
