import React, { useState, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";
import validator from "validator";

export default function Reviews() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [ErrorMessageVisible, setErrorMessageVisible] = useState(false);
  const [ErrorMessageType, setErrorMessageType] = useState("error");
  const [ErrorMessage, setErrorMessage] = useState("");

  const showSnackbarMessage = useCallback(
    (IsVisible, MsgType, Msg) => {
      setErrorMessageVisible(IsVisible);
      setErrorMessageType(MsgType);
      setErrorMessage(Msg);
    },
    [setErrorMessageVisible, setErrorMessageType, setErrorMessage]
  );

  const SaveReview = useCallback(() => {
    if (Name === "") {
      showSnackbarMessage(true, "error", "Please enter name.");
      return false;
    }
    if (Email === "") {
      showSnackbarMessage(true, "error", "Please enter email address.");
      return false;
    }
    if (validator.isEmail(Email) === false) {
      showSnackbarMessage(true, "error", "Please enter valid email address.");
      return false;
    }
    if (Message === "") {
      showSnackbarMessage(true, "error", "Please enter message.");
      return false;
    }

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        ApiKey: process.env.REACT_APP_API_KEY,
        Accept: "application/json",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      }),
      body: JSON.stringify({
        Name: Name,
        Email: Email,
        Message: Message,
      }),
    };

    fetch(process.env.REACT_APP_API_PATH + "SaveReview", requestOptions)
      .then((result) => result.json())
      .then((response) => {
        if (response.Success === true) {
          showSnackbarMessage(
            true,
            "success",
            "Your review request has been sent."
          );
          setName("");
          setEmail("");
          setMessage("");
        } else {
          showSnackbarMessage(true, "error", "Unable to sent review request");
        }
      });
  }, [Name, Email, Message, showSnackbarMessage]);

  return (
    <>
      <Snackbar
        open={ErrorMessageVisible}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={4000}
        onClose={() => {
          setErrorMessageVisible(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessageVisible(false);
          }}
          severity={ErrorMessageType}
        >
          {ErrorMessage}
        </Alert>
      </Snackbar>
      <section className="contact reviews py-5" id="Reviews">
        <div className="container py-xl-3 py-lg-2">
          {/* <h3 className="w3ls-title text-wh text-center font-weight-bold mb-5">
            Reviews
          </h3> */}
          <div className="row contact-form">
            <div className="offset-lg-2"></div>
            <div className="col-lg-8 wthree-form-left">
              <fieldset className="contact-top1" data-blast="borderColor">
                <legend className="text-wh let text-capitalize">
                  <h3 className="w3ls-title text-wh text-center font-weight-bold">
                    Reviews
                  </h3>
                </legend>
                <div className="form-group">
                  <label htmlFor="contactusername">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={Name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    required
                    placeholder="Enter your name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactemail">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={Email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    required
                    placeholder="Enter your Email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactcomment">Review Message</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    name="message"
                    value={Message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    required
                    placeholder="your message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-block"
                  data-blast="bgColor"
                  onClick={(e) => {
                    e.preventDefault();
                    SaveReview();
                  }}
                >
                  Submit
                </button>
              </fieldset>
            </div>
            <div className="offset-lg-2"></div>
          </div>
        </div>
      </section>
    </>
  );
}
