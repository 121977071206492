import React from "react";

export default function CommunityReach() {
  return (
    <>
      <section className="py-5 clients" id="CommunityReach">
        <div className="container py-xl-5 py-lg-3">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <div className="feedback-top">
                <h2 className="text-da text-center font-weight-bold mb-3">
                  Community Reach
                </h2>
                <p>
                  From time to time we engage in reaching out to the community
                  in whatever we can.Here are few of projects. Currently we are
                  trying to raise funds for{" "}
                  <a
                    href="https://www.facebook.com/ecobgc/posts/419475539829797 "
                    target={"_blank"}
                  >
                    <b>KRR old age homes</b>
                  </a>
                </p>
                <h5
                  className="mt-4 text-da font-weight-bold mb-2"
                  id="HelpingFireVictims"
                >
                  Helping Fire Victims
                </h5>
                <p>
                  When people especially college going kids were rendered
                  homeless because of a fire in an apartment Barbie’s along with
                  GATA collected essentials to help these people.
                </p>
                <div className="row pt-4">
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims6.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree my-md-0 my-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims2.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims5.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims4.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims1.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
                    <div className="gallery-demo" id="Oldagehomes_first">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/fire_victims3.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <h5
                  className="mt-5 text-da font-weight-bold mb-2"
                  id="Oldagehomes"
                >
                  Old age homes
                </h5>
                <p>Helped distraught women connect with Raksha organization.</p>

                <h5 className="mt-5 text-da font-weight-bold mb-2" id="NAAM">
                  NAAM
                </h5>
                <p>
                  Children dropped out of school because of lack of access to
                  technology during COVID lockdown, Barbie’s contributed to NAAM
                  efforts in providing laptops to some of the under privileged
                  kids around Georgia.
                </p>

                <h5
                  className="mt-5 text-da font-weight-bold mb-2"
                  id="YugalKunj"
                >
                  Yugal Kunj
                </h5>
                <p>
                  This temple located in Duluth engages kids in productive
                  activities teaching them about sustainability, when they were
                  looking for help with their garden Barbie’s connected them to
                  Green cell and helped to establish a system for composting,
                  recycling and greener practices.
                </p>
                <div className="row pt-4">
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/yugal_kunj1.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree my-md-0 my-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/yugal_kunj2.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/yugal_kunj3.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree mt-md-0 mt-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/yugal_kunj5.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <h5 className="mt-5 text-da font-weight-bold mb-2" id="Women">
                  Women
                </h5>
                <p>Helped distraught women connect with Raksha organization.</p>

                <h5
                  className="mt-5 text-da font-weight-bold mb-2"
                  id="HelpingLocalFarmers"
                >
                  Helping Local Farmers
                </h5>
                <p>
                  support local businesses and farmers. We did a small bit by
                  helping them with marketing their products.
                </p>
                <div className="row pt-4">
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/helping_farmers1.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree my-md-0 my-4">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/helping_farmers2.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-md-3 gal-grid-wthree">
                    <div className="gallery-demo">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/helping_farmers3.jpg`}
                        alt=" "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <h5
                  className="mt-5 text-da font-weight-bold mb-2"
                  id="Helpingyounginventor"
                >
                  Helping windmill inventor young Madhu
                </h5>
                <p>Helping and motivating young inventors.</p>
              </div>
            </div>
            <div className="col-lg-5 col-sm-4 col-2"></div>
          </div>
        </div>
      </section>
    </>
  );
}
